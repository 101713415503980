.ui.image.solution {
    min-width: 300px;
}

.ui.solution.modal .content {
    min-height: 256px;
}

.ui.solution.form.modal .description {
    flex: 1;
}
