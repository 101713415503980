
polyline {
  marker-end: url(#arrowhead);
  marker-mid: url(#arrowmid);
  }

#arrowhead {
  opacity: 0.8;
  }

#arrowmid {
  opacity: 1;
  }
