#map {
  height: 100vh;
  width: 80vw;
}

#root > .container {
  display: flex;
  width: 100vw;
  height: calc(100vh - 93px);
}

#root > .container > div:first-child {
  width: auto;
  flex-grow: 1;
}

#root > .container > div:last-child {
  width: 400px;
  overflow-y: scroll;
}

#map .ui.input.suggest {
  position: absolute;
  right: 100px;
  top: 13px;
  left: auto;
  bottom: auto;
  padding: 4px;
  width: 338px;
  max-width: 338px;
  z-index: 100;
}

@media (max-width: 800px) {

#map .ui.input.suggest {
    display: none
  }
}

#map .ui.input.suggest ul {
  top: 41px;
  width: 330px;
}
