.ui.menu > .item > .select {
  min-width: 700px;
}

.react-kanban-column-header {
  position: relative;
}

.add-column {
  width: 320px;
  margin: 5px;
}
