div.marker.ui.circular.label {
    opacity: 0.9;
    border-width: 2px;
    white-space: nowrap;
    color: black !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 3px  black;
}

div.marker.ui.circular.label.active {
    box-shadow: 0px 0px 10px 3px red;
}
