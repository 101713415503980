.dashboard {
  background-image: url(images/background.jpg);
  background-color: black; 
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh; 
  padding-top: 3em; 
}

.dashboard .ui.segment {
  display: flex;
  justify-content: space-around;
  flex-direction: column; 
  align-items: center; 
  border: none
}

.dashboard .select {
  width: 100vw
}
