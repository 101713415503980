main > div {
  height: 100vh;
}

div.rdg {
  height: 90vh;
}

p.count {
  margin: 0.5em;
  font-style: italic;
  color: #666;

  text-align: right;
}

div.unassigned {
  background: rgba(255, 0, 0, 0.03);
}
div.assigned {
  background: rgba(0, 255, 0, 0.03);
}

.react-kanban-column .ui.card:last-child {
  margin-bottom: 0.5em;
}
.react-kanban-column .ui.card .content .header:not(.ui) {
  font-size: 1em;
}

.ui.modal > .image.content {
  min-height: 272px;
}

.segment.basic.puzzle-card-board, .react-kanban-board {
  background-image: url(images/background.jpg);
  background-color: black; 
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.ui.attached.menu {
  overflow-x: scroll
}

.image-group {
    display: flex;
    margin: 1em auto;
    width: 100%;
    justify-content: center;
    gap: 1em;

}


.image-group    img {
        max-height: 200px;
        max-width: 45vw;
        object-fit: contain;
  }
