#root>.container {
  display: flex;
  width: 100vw;
  height: calc(100vh - 93px);
}

#root>.container>div:first-child {
  width: auto;
  flex-grow: 1;
  min-height: 300px;
}

#root>.container>div:last-child {
  width: 400px;
  overflow-y: scroll;
  border-left: 1px solid #d4d4d5;
  padding-bottom: 2em;
}


#root>.container>div:last-child>div {
  border-top: 0px
}

.absolute-hint-handle {
  background: red;
}

@media (max-width: 800px) {
  #root>.container {
    flex-direction: column;
  }

  #root>.container>div:last-child {
    width: 100vw;
  }
}
